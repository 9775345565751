
import { Options, Vue } from "vue-class-component";
// import MasterView from '@/views/MasterView.vue'; // @ is an alias to /src
import router from "@/router";
import Vuex from "vuex";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    // MasterView,
    Header,
    Footer,
  }, computed: {
    ...Vuex.mapState([
      "cantidadItem",
      "ventaModel",
      "valorTotal",
      "UserLogued",
      "valorTotal",
      "Impuestos",
      "Subtotal",
    ]),
  },methods: {
    onScrollBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  },
    //metodo para abrir modal
    openModal() {
      this.$refs.hijorRef.open();
    },
    openModalPassword() {
      this.$refs.hijorRef.openChagePass();
    },
    isChecout() {
    return this.$route.name == "checkout" || this.$route.name == "setting";
  }
    
  },
})
export default class App extends Vue {
  NotSinglePage() {
    return router.currentRoute.value.meta.NotSinglePage;
  }
}
