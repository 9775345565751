/* eslint-disable */
import { createStore } from 'vuex'
import Ventas from '../models/ventas';
import Productos from '../models/productos'; 
import Invtran from '../models/invtran';
import store from '@/store';
import Usuarios from '../models/usuarios';
import Marcas from '@/models/marcas';
import Categorias from '@/models/categorias';
import Configuration from '../models/configuration';
import { uuid } from 'vue-uuid'; 
import Subcategorias from '@/models/Subcategorias';

export default createStore({
  state: {
  refresh:0 as number,
   ventaModel:{} as Ventas,
   cantidadItem:0,
   valorTotal:0 as number,
   Impuestos:0 as number,
   Subtotal:0 as number,
   precioMin:0 as number,
   defaultCantidad:1 as number,
   PrecioMax:0 as number,
   loggedIn:false,
   UserLogued:{puedeSeleccionarEmpresa:false} as Usuarios,
   Marcas :[] as Marcas[], 
   Subcategorias :[] as Subcategorias[], 
   categorias :[] as Categorias[], 
   globalTextFilter:'',
    //UrlServer:'http://cloudwatersoft.com:5191/',
  //  UrlServer:'http://69.28.84.171:23100/',
   UrlServer:'https://www.hatueytienda.pro/',

   UrlImagenTransferencia:'' as string,
   urlPdf:'' as string,
   urlApi:'hatuey',
   listadoProductos: [] as Productos[],
   Codmoneda:'RD$',
   Configuration:{
      Codempresa:'01',
      Codalm:'001',
      Codclte:'',
      Coddoc:'',
      UrlImagenTransferencia:"",
      UrlPdfContrato:"https://s29.q4cdn.com/175625835/files/doc_downloads/test.pdf",
      NivelPrecio:2,
   } as Configuration,
   abortController: new AbortController()
 
   

   
  },
  getters: { 
  },
  mutations: {
    setCodmoneda(state,codmoneda:string){
      state.Codmoneda=codmoneda;
    },
    setToken(state,token:Configuration){
      state.Configuration=token;
      state.UrlImagenTransferencia=token.UrlImagenTransferencia;
      state.urlPdf=token.UrlPdfContrato 
      
      sessionStorage.setItem('Token', JSON.stringify(token));

    },
    setRefresh(state,element:number){
      state.refresh=element;
    },
    setUserLogued(state,element:Usuarios){

      state.UserLogued = element;
      if(element){
        // save data in local storage
        element.Clave = '';
        sessionStorage.setItem('UserLogued', JSON.stringify(element));

      }else{
        sessionStorage.removeItem('UserLogued');
      }
      
    },
    setProductos(state,element:Productos[]){
      state.listadoProductos = element;
    },
    setVentaModel(state,element:Ventas){
      state.ventaModel = element; 

    }, 
    setglobalFilter(state,element:string){
      state.globalTextFilter = element;
       

    },
    setFiltroMarcas(state){
     state.Marcas= state.Marcas.slice();
     state.categorias= state.categorias.slice();
     state.Subcategorias= state.Subcategorias.slice();
         
    },
    setFiltroSubcategorias(state){
      state.Subcategorias= state.Subcategorias.slice();
      state.Marcas= state.Marcas.slice();
      state.categorias= state.categorias.slice();
   
          
     },
    setMarcas(state,element:Marcas[]){
      state.Marcas = element; 
    },
    setMarcas2(state,element:Marcas[]){
      state.Marcas = element; 
    },
    setPreciosMin(state,  element:number){
      state.precioMin = element;
    },
    setdefaultCantidad(state,  element:number){
      state.defaultCantidad = element;
   
      
    },
    setPreciosMax(state,  element:number){
      state.PrecioMax = element;
      
    },
    setCategorias(state,element:Categorias[]){
      state.categorias = element;
     
    },
    setSubCategorias(state,element:Subcategorias[]){
      state.Subcategorias = element;
     
    },
    setclearAlldata(state){
      state.ventaModel = {} as Ventas;
      state.cantidadItem=0;
      state.valorTotal=0;
      state.Impuestos=0;
      state.Subtotal=0;
      state.precioMin=0;
      state.PrecioMax=0; 
      state.Marcas =[] as Marcas[];
      state.categorias =[] as Categorias[];
      state.globalTextFilter='';
      state.listadoProductos= [] as Productos[];
 
      sessionStorage.clear();
 
       
      state.ventaModel ={
        Coddoc:'',
        Codterm:'',
        Codempresa:'01',
        Codmoneda:'RD$',
        Codalm:'01',
        Cgcontrol:uuid.v4(),
        Tipo_trans:'A',
        Codclte:'',
        Usuario:'SFPEDWEB',
        Tasa:1,
        Divisa: 1, 
        Resestatus:'7',
        F_mov:new Date(),
        Numero:'',  
          Cgclasi:'',
          Codentr:'',
          Codetapa:'',
          Codproyec:'',
          Codvende:'001',
          Condicion:'',
          Desc_gral:0,
          Desc_item:0,
          Descuento:0,
          Entregado:'',
          F_registro:new Date(), 
          Factor_mul:0,
          Flete:0,
          Forma_pago:'',
           Impuesto:18,
           Itbis_inclu:false,
           Ncf_afectado:'',
           Monto_dev:0,
           Ncf_secuencia:'',
           No_i_fiscal:'', 
          No_orden:'',
          Noclie:'',
          Nota:'',
          Origen:'0',
          Por_defec:false,
          Rnc_cedula:'',
          Selectivo:0,
          Signo:0,
          Tiene_item:false,
          Val_itbis:0,
          Valor:0,
          Valordoc:0,
          
          Invtrans: []
     } as Ventas
    },
    realizaralculo(state){
      let  codclte = state.Configuration?.Codclte;
      if(store.state.UserLogued?.Nombre && store.state.UserLogued!.Empresas![0]!.ClienteData){
        codclte = store.state.UserLogued!.Empresas![0]!.ClienteData!.Codclte!;

        state.ventaModel.Codclte = codclte;
      }
      if( state.ventaModel.Codclte?.length==0){
        state.ventaModel.Codclte =state.Configuration?.Codclte;
      }
      state.ventaModel.Coddoc = state.Configuration?.Coddoc;
      state.valorTotal = 0;
      state.Subtotal=0;
      state.ventaModel.Usuario = store.state.UserLogued?.Nombre!;
      state.Impuestos=0;
      let index = 0;
      state.ventaModel.Invtrans.forEach(element => {
        element.Numero_Renglon = index+1;
        index++;
        element.Coddoc = state.ventaModel.Coddoc;
        element.Balance = element.Cantidad;
        element.Codempresa = state.ventaModel.Codempresa;
        element.Cgcontrol = state.ventaModel.Cgcontrol;
        state.Subtotal +=element.Precio*element.Cantidad;
       
        
        if(element.P_itbis==true){
          state.Impuestos += (element.Precio*element.Cantidad)*(element.Impuesto??0.0)*0.01;

        }
      });
      state.valorTotal  =state.Subtotal+state.Impuestos;
      state.ventaModel.Valor = state.Subtotal;
      state.ventaModel.Val_itbis = state.Impuestos;
      state.ventaModel.Valordoc = state.valorTotal;
      state.cantidadItem  =state.ventaModel.Invtrans.length;
      sessionStorage.setItem('ventaModel', JSON.stringify(state.ventaModel));
    },
    eliminarItemCarrito(state,data:Invtran){
      const index = state.ventaModel.Invtrans.findIndex(x => x.Codart?.trim() === data.Codart?.trim());
      state.ventaModel.Invtrans.splice(index,1);
      state.cantidadItem  =state.ventaModel.Invtrans.length;
      store.commit('realizaralculo');
    },
    agregarItemProduct(state,element:Productos){
       
     
      if(state.ventaModel.Invtrans==null)
      {
        state.ventaModel.Invtrans = [];
      }
      // primero busco el producto en el array de productos
      // si no lo encuentro lo agrego
      // si lo encuentro solo aumento la cantidad
      const index = state.ventaModel.Invtrans?.findIndex(x => x.Codart?.trim() === element.Codart?.trim());
      if (index === -1) {
      state.ventaModel.Invtrans.push(
        {
          Coddoc:state.ventaModel.Coddoc, 
          Codempresa:state.ventaModel.Codempresa,
          Codmoneda:state.ventaModel.Codmoneda,
          Codalm:state.ventaModel.Codalm,
          Cgcontrol:state.ventaModel.Cgcontrol,
          Tipo_trans:state.ventaModel.Tipo_trans,
          Codclte:state.ventaModel.Codclte,
          // Usuario:state.ventaModel.Usuario,
          Tasa:state.ventaModel.Tasa,
          Divisa: state.ventaModel.Divisa,
          F_mov:state.ventaModel.F_mov,
          Numero: '',
          Codart: element.Codart, 
          Codvende: state.ventaModel.Codvende,
          Origen: state.ventaModel.Origen,
          Codund: '',
          Codproyec: '',
          Coddispensador: '',
          Codmanguera: '',
          Codturno: '',
          Codtrabajo: '',
          Codetapa: '',
          Codactivo: '',
          Referencia: '', 
          Pendiente: 0,
          Codcomisio:  '',
          Nota_renglon: '', 
          F_registro: new Date(),
          Procesado: false,
          T_partida: false,
          L_costo: false,
          Impuesto: 18  ,
          Precio: (element.Precio_Venta),
          Cantidad:state.defaultCantidad,
          Balance: state.defaultCantidad,  
          Descuento:0,
          Detalle: '',
          Lista_seriales: '', 
          Ref_coddoc: '',
          Ref_numero: '',
          P_itbis: !element.No_paga_impuesto,
          Es_combo: false, 
          Despacho: false,
          Nodo: '',
          Alto: 0,
          Ancho: 0,
          Largo: 0,
          Piezas: 0,
          Unds_enva: 0, 
          Itbis_inclu: false,
          Codclasi: '', 
          Id_referen: '',
          Tran_aceptada: true,
          Motivo_devolucion: '',
          Selectivo: 0, 
          Tasa_referencia: 1, 
          Signo: 0,  
          Articulo: element.Articulo,
          Producto:element,
          Id_trans: uuid.v4(),
         }
      );
    }else{
      if( state.ventaModel.Invtrans[index])
        state.ventaModel.Invtrans[index].Cantidad +=  state.defaultCantidad;
        state.ventaModel.Invtrans[index].Balance =  state.ventaModel.Invtrans[index].Cantidad;
    }
    state.cantidadItem  =state.ventaModel.Invtrans.length;
    store.commit('realizaralculo');
  }
  },
  actions: {
   
    
  },
  modules: {
  },
})
