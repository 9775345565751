
import usrEmpresas from "@/models/usrEmpresas"; // @ is an alias to /src
import ClienteData from "@/models/clienteData";
import Productos from '@/models/productos';
import Usuarios from '@/models/usuarios';
import Ventas from '@/models/ventas';
import router from '@/router';
import store from '@/store';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Options } from 'vue-class-component';
import { Vue } from 'vue-class-component'; 
import Vuex from 'vuex';  
import Modal from './Modal.vue';

@Options({
  components: {
    Modal,
  },
  props: {
  },
  computed: {
    ...Vuex.mapState(['cantidadItem','ventaModel','valorTotal','UserLogued','valorTotal','Impuestos','Subtotal'])
  } ,
  data() {
    return {
      showModal: false,
      showModalPassword: false,
      dataLoaded: false,
      searchTerm: '',
     clients: [],
     logoEmpresa: require('@/assets/frontend/images/logoempresa.png'),
     logoUser: require('@/assets/frontend/images/user.png'),
    }
  },
  methods: {
  search() {
    
    // Aquí, realiza la llamada al servidor para buscar clientes con el término de búsqueda.
    // A continuación, un ejemplo con datos ficticios:
    axios
    .get(
      `${store.state.UrlServer}${store.state.urlApi}/api/DataManager/buscarcliente/?param1=${this.searchTerm}&Codempresa=${store.state.Configuration.Codempresa}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + store.state.UserLogued.Token,
          Codempresa: store.state.Configuration.Codempresa,
        },
      }
    )
    .then(
      (response) => {
        
        
         this.clients =response.data.Result;
        this.dataLoaded = true;

      },
      (error) => {
        this.cargandoProductos = false;
        console.log(error);
      }
    );
    
  },

  selectClient(client: any) {
    this.clients = [];
    this.searchTerm = '';
    const datausr = sessionStorage.getItem('UserLogued');
      
      if (datausr) {
          if (datausr.length > 0) {
            
            let buscarProductos =false;
            const user = JSON.parse(datausr) as Usuarios;
            if (user) {
              
              user.Empresas =[] as usrEmpresas[];
              user.Empresas.push({} as usrEmpresas);
              user.Empresas![0].ClienteData ={} as ClienteData;
              let antCliente=user.Empresas![0].ClienteData;
              user.Empresas![0].ClienteData = client;
            }
           
      if (user.Configuration != null) {
        this.ventaModel.Coddoc = user.Configuration.Coddoc;

      }
      buscarProductos =true;
    
     
      store.commit('setUserLogued', user);
      if(buscarProductos ){
        store.commit('setProductos', []);

      }
      // Si el cliente actual tiene un nivel de precio o el cliente anterior tenia un nivel de precios
       
    }
  }
    this.showModal = false;
   
  }
  ,
  close() {
    this.clients = [];
    this.showModal = false;
  },
  closeChagePass() {
 
    this.showModalPassword = false;
  },
},
})
export default class Header extends Vue {
  showModal= false;
  showModalPassword= false;
  textFilter ='';
  claveActual = '';
  claveNueva = '';
  ventaModel = {} as Ventas;
  clinteData ={} as ClienteData;
  AllClientes =[{}] as ClienteData[];
  viewFitler(){
    document.getElementsByClassName('header-form')[0].classList.toggle('active')
  }
  setglobalFilter(){
   
      
    store.commit('setglobalFilter',this.textFilter);
  }
  formatoDecimal(value:any) {
    if(!value){
    return "0.00"
  }
        const number = parseFloat(value);
        if (isNaN(number)) return value; // O manejar el error como prefieras

        return new Intl.NumberFormat('es-DO', { minimumFractionDigits: 2 }).format(number);
  }
  showModalChangePassword(){
    // Crea un modal para cambiar la contraseña
    // this.$bvModal.show('modalChangePassword');
    this.showModalPassword = true;
  }
reloadImages(){

// Recarg1arImagenes
axios
    .get(
      `${store.state.UrlServer}${store.state.urlApi}/api/FilesManager/Recarg1arImagenes`,
      {
        headers: {
          "Content-Type": "application/json",
          "Codempresa": store.state.Configuration.Codempresa,
          Authorization: "Bearer " + store.state.Configuration.DefaultToken,
        },
      }
    )
    .then(      );
    


}
isChecout(){
  return this.$route.name == 'checkout' || this.$route.name == 'setting';
}
  CargarLocal() {
  const data = sessionStorage.getItem('ventaModel');
  if (data) {
    this.ventaModel = JSON.parse(data) as Ventas;
    store.commit('setVentaModel', this.ventaModel);
    store.commit('realizaralculo');
  }
  const datausr = sessionStorage.getItem('UserLogued');
      
      if (datausr) {
          if (datausr.length > 0) {
      const user = JSON.parse(datausr) as Usuarios;

      if (user.Configuration != null) {
        this.ventaModel.Coddoc = user.Configuration.Coddoc;

      }

      store.commit('setUserLogued', user);
       
    }
  }
}
SaveCambioPass(){
    this.showModalPassword = false;
    const datausr = sessionStorage.getItem('UserLogued');
    if (datausr) {
      if (datausr.length > 0) {
        const user = JSON.parse(datausr) as Usuarios;
     
        
      axios
    .post(
      `${store.state.UrlServer}${store.state.urlApi}/api/Auth/CambiarClave/${user.Nombre}/${this.claveActual}/${this.claveNueva}`,{},
      {
        headers: {
          "Content-Type": "application/json",
          "Codempresa": store.state.Configuration.Codempresa,
          Authorization: "Bearer " + user.Token,
        },
      }
      ).then( (resp:any)=>{
      
        this.claveActual='';
        this.claveNueva='';
      var mensaje=resp.data.Messaje;
      
      Swal.fire({
        title: 'Exitoso',
        text:'La contraseña se cambio correctamente' ,
        icon: 'success',
        confirmButtonText: 'OK'
      });
    }).catch((err:any)=>{
      
      var mensaje=err.response.data.Messaje;
      Swal.fire({
        title: 'Error',
        text: mensaje,
        icon: 'error',
        confirmButtonText: 'OK'
      });
      this.claveActual='';
        this.claveNueva='';
    });
       
    }
  }
   
  }
  navigateToPag(){
      // Confirm
      Swal.fire({
          title:'Confirmar',
          text: 'Si sale se perderan los datos, desea continuar?',
          icon: 'question',
          showCancelButton:true,
          confirmButtonText: 'Sí',
          cancelButtonText:'No',
      }).then((val)=>{
          if(val.isConfirmed){
              store.commit('setUserLogued',null);
              // router.push('/home');
              router.push( {name:'login'} );                 
          }
      }) 
  }
  navigatetoSetting(){
      router.push( {name:'setting'} );
  }

  created() {
    this.CargarLocal();
  }

 


}


